/* Home.js */

import React from 'react';
import AutoCarousel from './AutoCarousel';

function Home() {
    return (
        <section id="home" className="relative flex-row w-full overflow-clip">
            <AutoCarousel />
        </section>
    );
}

export default Home;