/* Footer.js */

import React from 'react';
import { ReactComponent as Insta } from "../assets/instagram.svg";
import { ReactComponent as Facebook } from "../assets/facebook.svg";
import { ReactComponent as ArtStation } from "../assets/artstation.svg";
import { ReactComponent as Cara } from "../assets/cara.svg";

function Footer() {
    return (
        <footer className='pt-4 text-latte text-xs'>
            <nav className='inline-flex space-x-6 landscape:hidden'>
                <a className="size-7 sm:size-0 fill-current hover:fill-weak-tea" href='https://www.instagram.com/tal.a.r.t'><Insta /></a>
                <a className="size-7 sm:size-0 fill-current hover:fill-weak-tea" href='https://www.facebook.com/profile.php?id=61566903778983'><Facebook /></a>
                <a className="size-7 sm:size-0 fill-current hover:fill-weak-tea" href='https://www.artstation.com/tal-art'><ArtStation /></a>
                <a className="size-7 sm:size-0 fill-current hover:fill-weak-tea" href='https://cara.app/talart/portfolio/all'><Cara /></a>    
            </nav>
            <p className="pt-3 sm:pt-0">Copyright &copy; Tal Goodisman 2024  |   Designed and coded by Tal Goodisman</p>
        </footer>
    );
}

export default Footer;