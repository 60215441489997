/* Gallery.js */

import React, { useState } from 'react';

const artworks = [
    { id: 1, imgSrc:require('../assets/gallery/a_bite_to_eat_2016.jpg'), title: 'A Bite to Eat', price:'£220', year: '2016', size: '36.0 x 40.4 cm', medium: 'Acrylic on cardboard', exLoc: 'Not exhibited'},
    { id: 2, imgSrc:require('../assets/gallery/sweet_nectarine_2024.jpg'), title: 'Nectarine', price:'£180', year: '2024', size: '28.2 x 25.1 cm', medium: 'Acrylic on cardboard', exLoc: 'Not exhibited'},
    { id: 3, imgSrc:require('../assets/gallery/summer_strawberries_2024.jpg'), title: 'Summer Strawberries', price:'£270', year: '2024', size: '50.0 x 39.9 cm', medium: 'Acrylic on cardboard', exLoc: 'Not exhibited'},
    { id: 4, imgSrc:require('../assets/gallery/red_onion_2024.jpg'), title: 'Red Onion', price:'£240', year: '2024', size: '36.6 x 33.1 cm', medium: 'Acrylic on cardboard', exLoc: 'Not exhibited'},
    { id: 5, imgSrc:require('../assets/gallery/tart_and_crisp_2024.jpg'), title: 'Tart and Crisp', price:'£130', year: '2024', size: '24.5 x 25.6 cm', medium: 'Acrylic on cardboard', exLoc: 'Not exhibited'},
    { id: 6, imgSrc:require('../assets/gallery/four_seasons_2024.jpg'), title: 'Four Seasons', price:'NA', year: '2024', size: '43.2 x 32.7 cm (unframed)', medium: 'Acrylic on cardboard', exLoc: 'Not exhibited'},
    { id: 7, imgSrc:require('../assets/gallery/garden_rose_2024.jpg'), title: 'Garden Rose', price:'£200', year: '2024', size: '25.4 x 29.0 cm', medium: 'Acrylic on cardboard', exLoc: 'Not exhibited'},
    { id: 8, imgSrc:require('../assets/gallery/morning_treat_2024.jpg'), title: 'Morning Treat', price:'NA', year: '2024', size: '26.0 x 19.0 cm (unframed)', medium: 'Acrylic on cardboard', exLoc: 'Not exhibited'},
];

function Gallery() {
    const [selectedArt, setSelectedArt] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);

    const openModal = (index) => {
        setSelectedIndex(index);
        setSelectedArt(artworks[index]);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedIndex(-1);
        setSelectedArt(null);
        setIsModalOpen(false);
    };

    const showNext = () => {
        if (selectedIndex < artworks.length - 1) {
            setSelectedIndex(selectedIndex + 1);
            setSelectedArt(artworks[selectedIndex + 1]);
        }
    };

    const showPrev = () => {
        if (selectedIndex > 0) {
            setSelectedIndex(selectedIndex - 1);
            setSelectedArt(artworks[selectedIndex - 1]);
        }
    };

    return (
        <section id="gallery" className="p-4 border">
            <div className="grid grid-cols-1 items-center">
            {artworks.map((art, index) => (
                <div key={art.id} className='flex justify-center items-center py-2' onClick={() => openModal(index)}>
                    <img src={art.imgSrc} alt={art.title} className="w-full landscape:w-2/3 pointer-events-none" />
                </div>
            ))}
            </div>

            {isModalOpen && selectedArt && (
                <div className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50">
                    <div className="card relative">
                        {/* Close Button */}
                        <button onClick={closeModal} className="absolute top-3 right-6 text-4xl text-creamer hover:text-latte">
                        &times;
                        </button>

                        {/* Left Button */}
                        <button onClick={showPrev} className={`absolute top-8 left-2 transform -translate-y-1/2 
                        text-creamer text-4xl px-4 py-2 
                        ${selectedIndex === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:text-latte'}`}
                        disabled={selectedIndex === 0}>
                        &laquo;
                        </button>

                        {/* Right Button */}
                        <button onClick={showNext} className={`absolute top-8 left-20 transform -translate-y-1/2 
                        text-creamer text-4xl px-4 py-2 
                        ${selectedIndex === artworks.length - 1 ? 'opacity-50 cursor-not-allowed' : 'hover:text-latte'}`}
                        disabled={selectedIndex === artworks.length - 1}>
                        &raquo;
                        </button>

                        {/* Modal Content */}
                        <div className="flex flex-col landscape:flex-row h-screen">
                            <img src={selectedArt.imgSrc} alt={selectedArt.title} className="h-auto sm:h-4/5 left-5 object-contain pointer-events-none" />
                            <div className="mt-4 sm:mt-0 sm:ml-6">
                                <h3 className="text-creamer text-2xl mb-2">{selectedArt.title}, {selectedArt.year}</h3>
                                <p className="text-creamer mb-1">{selectedArt.medium}</p>
                                <p className="text-creamer mb-1">{selectedArt.size}</p>
                                <p className="text-creamer mb-1">{ (selectedArt.price === 'NA') ? "Not currently on sale" : selectedArt.price }</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
}

export default Gallery;