/* Contact.js */

import React from 'react';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';

function Contact() {
    
    const form = useRef();

    const sendEmail = (e) => {
        e.persist();
        e.preventDefault();

        emailjs
        .sendForm(
            'service_tal-art_mailtrap',
            'template_contact_form',
            form.current,
            'Kxi0MHlFbkCZhOK8P'
        )
        .then(
            (result) => { console.log('Message sent!', result.status, result.text); },
            (error) => { console.log('Something went wrong, please try again later', error.status, error.text); }
        );

        // Clears the form after sending the email
        e.target.reset();
    };
    
    return (
        <section className="p-8 border">
            <h2 className="text-3xl uppercase">Say Hello!</h2>
            <form ref={form} onSubmit={sendEmail} className="mt-4 caret-latte accent-latte">
                <input type="text" name='from_name' placeholder="name" className="border border-weak-tea rounded-md p-2 mb-4 w-full" required />
                <input type="email" name='from_email' placeholder="you@example.com" className="border border-weak-tea rounded-md p-2 mb-4 w-full" required />
                <textarea placeholder="message" name='message' className="border border-weak-tea rounded-md p-2 mb-4 w-full" rows="4" required></textarea>
                <button type="submit" value="Send" className="btn">SEND</button>
            </form>
        </section>
    );
}

export default Contact;