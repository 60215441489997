import React from 'react';
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Insta } from "../assets/instagram.svg";
import { ReactComponent as Facebook } from "../assets/facebook.svg";
import { ReactComponent as ArtStation } from "../assets/artstation.svg";
import { ReactComponent as Cara } from "../assets/cara.svg";
import { ReactComponent as Logo} from "../assets/logo_3.svg";

function Header() {
    
    const location = useLocation();

    const pages = [
        { name:'about', path:'/about' },
        { name:'gallery', path:'/gallery' },
        { name:'contact', path:'/contact' },
    ]

    return (
        <header className='pb-2 text-2xl text-latte font-Teko sm:flex sm:justify-between z-50'>
            <Link key='home' to='/'><Logo className='inline-flex w-40 fill-hot-chocolate border border-hot-chocolate'/></Link>
            <nav className="inline-flex pt-4 flex-wrap space-x-6 sm:place-content-end">
                {pages.map((item) => (
                    <Link
                    key={item.name}
                    to={item.path}
                    className={`${
                        location.pathname === item.path ? "font-bold uppercase text-hot-chocolate ultra-expanded" : "font-normal"
                    } hover:text-weak-tea`}
                    >
                    {item.name}
                    </Link>
                ))}
                <a className="size-0 sm:size-7 fill-current hover:fill-weak-tea" href='https://www.instagram.com/tal.a.r.t'><Insta /></a>
                <a className="size-0 sm:size-7 fill-current hover:fill-weak-tea" href='https://www.facebook.com/profile.php?id=61566903778983'><Facebook /></a>
                <a className="size-0 sm:size-7 fill-current hover:fill-weak-tea" href='https://www.artstation.com/tal-art'><ArtStation /></a>
                <a className="size-0 sm:size-7 fill-current hover:fill-weak-tea" href='https://cara.app/talart/portfolio/all'><Cara /></a>
            </nav>
        </header>
    );
}

export default Header;