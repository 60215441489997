import React from 'react';
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/splide.min.css";

const images = require.context('../assets/home-slideshow/', true);
const imageList = images.keys().map(image => images(image));

function shuffleArray(array) {
    for (let i = array.length - 1; i >= 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

shuffleArray(imageList);

function AutoCarousel() {
    return (
        <div className="relative flex h-full pointer-events-none">
            <div className="container max-w-screen-xl mx-auto relative z-20 overflow-x-hidden">
                <Splide
                    options={{
                        type: "loop", // Loop back to the beginning when reaching the end
                        autoScroll: {
                            pauseOnHover: false, // Do not pause scrolling when hovering over the carousel
                            pauseOnFocus: false, // Do not pause scrolling when the carousel is focused
                            rewind: true, // Rewind to start when the end is reached
                            speed: 2 // Scrolling speed
                        },
                        arrows: false, // Hide navigation arrows
                        pagination: false, // Hide pagination dots
                        fixedWidth: '400px', // Fixed width for each slide
                        gap: '12px', // Gap between slides
                    }}
                    extensions={{ AutoScroll }} // Use the AutoScroll extension
                >
                    {imageList.map((img) => (
                        <SplideSlide>
                            <img key={img} src={img} alt={img.replace(/^.*[\\/]/, "").replace(/\.[a-z0-9]*.jpg$/, "")} className="h-full w-auto object-cover"/>
                        </SplideSlide>
                    ))}
                </Splide>
            </div>
        </div>
    );
}

export default AutoCarousel;