/* About.js */
import React from 'react';
import image from '../assets/profile_photo.png';

function About() {
    return (
        <section id="about" 
        className="p-6 flex flex-col divide-weak-tea divide-y
        landscape:flex-row landscape:divide-x landscape:divide-y-0
        place-items-center border"
        >
            <div id="ab-img" className="pb-4 landscape:pb-0 landscape:pr-6 size-1/2 landscape:size-auto">
            <img 
                className="rounded-full"
                src={image}
                alt={'Artist Tal Goodisman'}
            />
            </div>
            <div id="ab-text" 
            className="pt-4 landscape:pt-0 landscape:pl-6 text-center size-3/4
            divide-weak-tea divide-y landscape:divide-y-0 landscape:size-auto"
            >
                <h2 className="text-5xl text-hot-chocolate font-bold font-Teko flow-root">Tal Goodisman is a contemporary artist based in 
                Stanmore with a focus on<br />fine art.</h2>
                
                <p className="text-lg mt-4 flow-root pt-4 landscape:pt-0">Taught locally from a young age, Tal discovered 
                the use of traditional mediums as a means of expression and respite from the challenges 
                of everyday life. Using a limited colour palette and cardboard packaging as a canvas, 
                his simplified approach to creating his pieces focuses on translating the often 
                overlooked beauty of everyday objects to the audience.</p>
            </div>
        </section>
    );
}

export default About;